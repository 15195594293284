import { Component } from '@angular/core';

@Component({
  selector: 'ume-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss'],
  standalone: true,
})
export class UnderConstructionComponent {
  constructor() {}
}
