<div class="restrict-page">
  <div class="restricted-content">
    <picture>
      <img
        src="/assets/svg/icon-less-effort.svg"
        alt="under constructions"
        height="136"
        width="184" />
    </picture>
    <h2>Under construction</h2>
    <p>We are performing system maintenance.</p>
    <p>
      During this time, some features may be temporarily unavailable. We appreciate your patience.
    </p>
  </div>
</div>
